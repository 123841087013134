import Popup from "@components/Popup";
import backgroundImage from "@img/Group.svg";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";

const DetailsField = observer(() => {
   const { isDetailsFieldOpen, setIsDetailsFieldOpen, detailsItem } = store;
   const areAllValuesEmpty = (obj) => {
      for (const value of Object.values(obj)) {
         if (!isEmptyValue(value)) {
            return false;
         }
      }
      return true;
   }
   const isEmptyValue = (value) => {
      return (
         value === null ||
         value === undefined ||
         (typeof value === 'string' && value.trim() === '') ||
         (Array.isArray(value) && value.length === 0) ||
         (typeof value === 'object' && Object.keys(value).length === 0)
      );
   }

   return (
      <Popup
         isOpen={isDetailsFieldOpen}
         setIsOpen={setIsDetailsFieldOpen}
         backgroundImage={backgroundImage}
         addStyleButtonClose={{ top: "20px", right: "20px" }}
      >
         {!areAllValuesEmpty(detailsItem) ? (
            Object.entries(detailsItem).map((item, index) => {
               return (
                  <div key={index}>
                     { item[1] ?
                        <article>
                           <h2 className="detailsItem__title">{item[0]}</h2>
                           <p className="detailsItem__paragraph">{item[1]}</p>
                        </article> : null
                     }
                  </div>



            )})) : (
            <article>
               <h2 className="detailsItem__title">
                  Данный раздел в процессе разработки
               </h2>
            </article>
         )}
      </Popup>
   );
});

export default DetailsField;
