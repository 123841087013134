import SketchCard from "@components/SketchCard/SketchCard";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";

const NavBar = observer(() => {
   const {dataQ,actualCard} = store

   return (
      <article className={`navBar ${actualCard.name !== undefined && 'whiteBg'}`}>
         <h3 className="navBar__title">Список основых продуктов:</h3>
         <ul className="navBar__list">
            {dataQ[0]?.map((item, index) => (
               <SketchCard item={item} index={index} key={index} />
            ))}

         </ul>
      </article>
   );
});

NavBar.propTypes = {
   items: PropTypes.array.isRequired
};

export default NavBar;
