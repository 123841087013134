import axios from "axios";

export const loadSheetData = async () => {
   try {
      const spreadsheetId = "16gSBxhj99R6FH3c3GC4tPB253McJRf1pkh-5bAM8POc";
      const range = "A1:Z";
      const apiKey = "AIzaSyBQfl4ei-HeNTSqcbkZ4Pe2i6A9F8Jo0N4";

      const response = await axios.get(
         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`
      );

      const data = response.data.values;

      if (data.length > 1) {
         const keys = data[0];
         const objects = [];

         for (let i = 1; i < data.length; i++) {
            const row = data[i];
            const object = {};

            for (let j = 0; j < keys.length; j++) {
               object[keys[j]] = row[j];
            }

            objects.push(object);
         }

         return objects;
      }
   } catch (error) {
      console.error("Ошибка при загрузке данных из Google Таблицы:", error);
   }
};

