import MainImage from "@components//MainImage";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";


const HomeField = observer(() => {
   const { actualCard, dataQ, setActualCard } = store;

   const textContainerClassName = `homeField__textContainer`;
   const imageClassName = `homeField__image `;


   return (
      <section className="homeField">
         {dataQ[0]?.map((item, index) => (
            <MainImage
               key={index}
               item={item}
               index={index}
            />
         ))}

         <div className={textContainerClassName}>
            <h1 className="homeField__title">
               Разработка собственных продуктов <br />
               <span className="homeField__title homeField__title_type_green">
                  Более 5 лет на рынке
               </span>
            </h1>
            <p className="homeField__subTitle">
               Для более детальной информации нажмите на превью проекта,
               расположенного справа на экране
            </p>
         </div>
         <div className={imageClassName}> <div onClick={setActualCard} className={`${actualCard.name !== undefined ? "homeField__backdrop" : ""}`}></div></div>
      </section>
   );
});

HomeField.propTypes = {
   items: PropTypes.array.isRequired,
   handleChangeDetailsItem: PropTypes.func.isRequired
};

export default HomeField;
