import { BsTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import Popup from "@components/Popup";
import backgroundImage from "@img/contactsGroup.svg";
import { observer } from "mobx-react-lite";
import store from "@store/store.js";

const ContactsField = observer(() => {
   const { isContactsFieldOpen, setIsContactsFieldOpen } = store;

   return (
      <Popup
         isOpen={isContactsFieldOpen}
         setIsOpen={setIsContactsFieldOpen}
         addStyleButtonClose={{ top: "20px", right: "20px" }}
         backgroundImage={backgroundImage}
      >
         <h4 className="contactsField__title">Наши контакты:</h4>
         <div className="contactField__wrapperContainer">
            <div className="contactField__textContainer">
               <div className="contactField__textWrapperContainer">
                  <p className="contactsField__personTitle">Садыков Тимур</p>
                  <p className="contactsField__personSubtitle">
                     Исполнительный директор
                  </p>
               </div>
               <div className="contactField__textWrapperContainer">
                  <p className="contactsField__personSubtitle">
                     <BsTelephoneFill className="contactsField__icon" />{" "}
                     <a href="tel:+79062374343" className='contactsField__link'>+7 (9062) 37-43-43 </a>
                  </p>
                  <p className="contactsField__personSubtitle">
                     <HiMail className="contactsField__icon" />{" "}
                      <a href="mailto:smartrulab@gmail.com" className='contactsField__link'>smartrulab@gmail.com</a>
                  </p>
               </div>
            </div>
            <div className="contactField__textContainer">
               <div className="contactField__textWrapperContainer">
                  <p className="contactsField__personTitle">Михаил Чухарев</p>
                  <p className="contactsField__personSubtitle">Директор</p>
               </div>
               <div className="contactField__textWrapperContainer">
                  <p className="contactsField__personSubtitle">
                     <BsTelephoneFill className="contactsField__icon" />{" "}
                      <a href="tel:+74012389373" className='contactsField__link'>+7 (4012) 38-93-73</a>
                  </p>
                  <p className="contactsField__personSubtitle"></p>
               </div>
            </div>
         </div>
         <div className="contactsField__info">
            <p className="contactsField__infoParagraph">
               ООО «Смарт Техно ЛАБ» (
               <a
                  className="contactsField__link"
                  href="http://www.stl.im/"
                  target="_blank"
                  rel="noreferrer"
               >
                  www.stl.im
               </a>
               )
            </p>{" "}
            <p className="contactsField__infoParagraph">
               Россия, 236010, г. Калининград, ул. Комсомольская, 85, помещение
               XII
            </p>{" "}
            <p className="contactsField__infoParagraph">
               ОГРН: 1193926001312, ИНН: 3906376858 E-mail: <a href="mailto:office@stl.im" className='contactsField__link'>office@stl.im</a>
            </p>{" "}
         </div>
      </Popup>
   );
});

export default ContactsField;
