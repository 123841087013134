import HomeField from "../HomeField/HomeField.jsx";
import NavBar from "../NavBar/NavBar";
import { observer } from "mobx-react-lite";
const Main = observer(() => {
   return (
      <main className="main">
         <HomeField />
         <NavBar />
      </main>
   );
});

export default Main;
