/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { MdClose } from "react-icons/md";
import PropTypes from "prop-types";

const Popup = ({
   isOpen,
   setIsOpen,
   addStyleButtonClose,
   backgroundImage,
   children,
}) => {
   const popupClassName = `popup ${isOpen ? "popup_type_active" : ""}`;
   const bg = css({
      "&::before": { backgroundImage: `url(${backgroundImage})` },
   });
   const handleClose = (evt) => {
      if (evt.target.classList.contains("popup_type_active")) {
         setIsOpen(false);
      }
   };

   return (
      <article className={popupClassName} onClick={handleClose}>
         <div className="popup__mainContainer" css={bg}>
            <MdClose
               className="popup__buttonClose"
               style={addStyleButtonClose}
               onClick={() => setIsOpen(false)}
            />
            {children}
         </div>
      </article>
   );
};

Popup.propTypes = {
   isOpen: PropTypes.bool.isRequired,
   setIsOpen: PropTypes.func.isRequired,
   addStyleButtonClose: PropTypes.object.isRequired,
   backgroundImage: PropTypes.string.isRequired,
   children: PropTypes.node.isRequired,
};

export default Popup;
